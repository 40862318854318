import React from "react";
import { Link } from "react-router-dom";
// import { LogoutIcon } from "@heroicons/react/solid";

const Navbar = () => {
  const disconnectString = localStorage.getItem("disconnect");
  const disconnect = eval("(" + disconnectString + ")");
  return (
    <nav className="flex items-center justify-between bg-gray-900 text-white py-2 px-8 shadow-lg">
      <div className="flex items-center align-middle justify-center">
        <div className="text-2xl font-serif text-white flex items-center align-middle justify-center">
          <img
            src="./sanlec.png"
            alt="Sanlec Automations"
            className="w-10 h-8 ml-2"
          />
          Sanlec Automations
        </div>
      </div>
      <div className="space-x-4 flex">
        <button className="flex items-center justify-center px-3 py-1 bg-blue-600 rounded-md text-white hover:bg-blue-700">
          <Link
            to="/"
            onClick={() => {
              disconnect();
              const sendfunc = localStorage.sendmsg;
              localStorage.clear();
              localStorage.sendmsg = sendfunc;
            }}
          >
            Log Out
          </Link>
          {/* <LogoutIcon className="w-5 h-5 ml-2" /> */}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
