import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Tank from "./Tank";
import Pump from "./Pump";
import Tag1 from "./Tag1";
import Login from "./Login";
import Home from "./Home";
import axios from "axios";

const Main = () => {
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  let [mqttdata, setMqttData] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  setInterval(() => {
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setMqttData(mqttdata);
  }, 2000);

  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  const handleChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  const data = {
    device_id: 213,
    // date: "2023-04-27",
    date: selectedDate,
  };

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  let msgArray = [];
  let message = [];
  let dataArr = [];
  let [pump1, setpump1] = useState([]);
  let [pump2, setpump2] = useState([]);
  let [pump3, setpump3] = useState([]);
  let [pump4, setpump4] = useState([]);
  let [pump5, setpump5] = useState([]);
  let [pump6, setpump6] = useState([]);

  // apis start: to fetch required data/ make arrays
  useEffect(() => {
    axios
      .post(
        "https://sanlec.smart-iot.in/api/super-admin/device/getdevicelogs",
        data,
        { headers }
      )
      .then(function (response) {
        // console.log(response.data.data);
        let x = response.data.data;
        let log = x.logs;
        // console.log(log[0].log_time);
        // console.log(logs.length);

        for (let i = 0; i < log.length; i++) {
          //accessing log_time from the log (api)
          // let y = log[i].log_time;

          // timeArray.push(convertTo24Hour(time));

          //accessing msg from the log (api)
          let w = log[i].msg;
          message.push(stringToArray(w));

          //to decrpt
          let array_index = 0;
          msgArray = [];
          for (let index = 0; index < 200; index++) {
            array_index = index * 2;
            msgArray[index] =
              Number(message[i][array_index]) * 256 +
              Number(message[i][array_index + 1]);

            dataArr[i] = msgArray;
            // console.log(dataArr[i]);
          }
        }
        // setTimeArray(timeArray);
        // console.log(timeArray);
        // console.log(dataArr);
        for (let i = 0; i < dataArr.length; i++) {
          // avgPfArr[i] = dataArr[i][30] / 100;
          // powerArr[i] = dataArr[i][14];
          // vAvg[i] = dataArr[i][3];
          // iAvg[i] = dataArr[i][11];
          // EnergyArr[i] = dataArr[i][12] * 65536 + dataArr[i][13];
          // currentLevel[i] =
          //   dataArr[i][100] * 256 + dataArr[i][101] > 649
          //     ? ((dataArr[i][100] * 256 + dataArr[i][101] - 649) * 37.5) / 1000
          //     : dataArr[i][100] * 256 + dataArr[i][101];
          // if (dataArr[i][100] * 256 + dataArr[i][101] > 649) {
          // currentLevel[i] =
          //   ((dataArr[i][100] * 256 + dataArr[i][101] - 649) * 37.5) / 1000;
          // pressure[i] =
          //   ((dataArr[i][100] * 256 + dataArr[i][101] - 649) * 37.5) / 10000;
          // }

          pump1[i] = dataArr[i][1] > 500 ? 1 : 0;

          pump2[i] = dataArr[i][2] > 500 ? 1 : 0;

          pump3[i] = dataArr[i][3] > 500 ? 1 : 0;

          pump4[i] = dataArr[i][4] > 500 ? 1 : 0;

          pump5[i] = dataArr[i][8] > 500 ? 1 : 0;

          pump6[i] = dataArr[i][9] > 500 ? 1 : 0;
        }
        // set here
        setpump1(pump1);
        setpump2(pump2);
        setpump3(pump3);
        setpump4(pump4);
        setpump5(pump5);
        setpump6(pump6);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate]);
  // apis end

  // console.log(pump1);
  // console.log(pump2);
  // console.log(pump3);
  // console.log(pump4);
  // console.log(pump5);
  // console.log(pump6);

  function countOnes(arr) {
    return arr.reduce((count, num) => count + (num === 1 ? 1 : 0), 0);
  }

  let pump1runn = countOnes(pump1);
  let pump2runn = countOnes(pump2);
  let pump3runn = countOnes(pump3);
  let pump4runn = countOnes(pump4);
  let pump5runn = countOnes(pump5);
  let pump6runn = countOnes(pump6);

  let ro_pump = mqttdata?.[0] ? (mqttdata[0][1] > 500 ? 1 : 0) : 0;
  let dm_water_paint_shop_pump = mqttdata?.[0]
    ? mqttdata[0][2] > 500
      ? 1
      : 0
    : 0;
  let softner_plant_pump = mqttdata?.[0] ? (mqttdata[0][3] > 500 ? 1 : 0) : 0;
  let dm_plant_pump = mqttdata?.[0] ? (mqttdata[0][4] > 500 ? 1 : 0) : 0;
  let jockey_pump = mqttdata?.[0] ? (mqttdata[0][8] > 500 ? 1 : 0) : 0;
  let main_pump = mqttdata?.[0] ? (mqttdata[0][9] > 500 ? 1 : 0) : 0;

  let ro_tank = mqttdata?.[0] ? (mqttdata[0][1] > 500 ? 1 : 0) : 0;
  let dm_water_paint_shop_tank = mqttdata?.[0]
    ? mqttdata[0][2] > 500
      ? 1
      : 0
    : 0;
  let softner_plant_tank = mqttdata?.[0] ? (mqttdata[0][3] > 500 ? 1 : 0) : 0;
  let dm_plant_tank = mqttdata?.[0] ? (mqttdata[0][4] > 500 ? 1 : 0) : 0;

  // console.log(main_pump)

  return (
    <div className="min-h-screen bg-indigo-100 ">
      <Navbar />

      <div className="">
        {/* PUMP */}
        <div className="">
          <div className="grid justify-end grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label htmlFor="datepicker" className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="px-1 py-1  border rounded-lg m-2 sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div>
          <div>
            <div className="flex justify-center items-center align-middle mt-20">
              <div className=" bg-white rounded-lg shadow-lg shadow-black p-4 m-2">
                <div className="text-center text-sm font-bold border-b border-b-black  ">
                  R.O PUMP
                </div>
                {ro_pump === 1 ? (
                  <>
                    <img
                      src="./empty1.gif"
                      alt="tank"
                      className="object-contain w-72 h-52 mb-2"
                    />
                  </>
                ) : (
                  <img
                    src="./fill.gif"
                    alt="tank"
                    className="object-contain w-72 h-52 mb-2"
                  />
                )}
                {/* <div>hello</div> */}
                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2 ">
                    PUMP STATUS:
                    <span
                      className={`${
                        ro_pump === 1 ? "bg-green-400" : "bg-red-400"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {ro_pump === 1 ? "ON" : "OFF"}
                    </span>
                  </p>
                </div>

                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2">
                    <span
                      className={`${
                        ro_tank === 0 ? "bg-blue-500" : "bg-yellow-500"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {ro_tank === 0 ? "TANK FULL" : "TANK EMPTY"}
                    </span>
                  </p>
                </div>
              </div>
              {/* tank 2 pump 2 */}
              <div className=" bg-white rounded-lg shadow-lg p-4 m-2 shadow-black">
                <div className="text-center text-sm font-bold border-b border-b-black  ">
                  DM WATER PAINT SHOP PUMP
                </div>
                {dm_water_paint_shop_pump === 1 ? (
                  <>
                    <img
                      src="./empty1.gif"
                      alt="tank"
                      className="object-contain w-72 h-52 mb-2"
                    />
                  </>
                ) : (
                  <img
                    src="./fill.gif"
                    alt="tank"
                    className="object-contain w-72 h-52 mb-2"
                  />
                )}
                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2">
                    PUMP STATUS:
                    <span
                      className={`${
                        dm_water_paint_shop_pump === 1
                          ? "bg-green-400"
                          : "bg-red-400"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {dm_water_paint_shop_pump === 1 ? "ON" : "OFF"}
                    </span>
                  </p>
                </div>

                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2">
                    <span
                      className={`${
                        dm_water_paint_shop_tank === 0
                          ? "bg-blue-500"
                          : "bg-yellow-500"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {dm_water_paint_shop_tank === 0
                        ? "TANK FULL"
                        : "TANK EMPTY"}
                    </span>
                  </p>
                </div>
              </div>
              {/* tank 3 pump 3 */}
              <div className=" bg-white rounded-lg shadow-lg p-4 m-2 shadow-black">
                <div className="text-center text-sm font-bold border-b border-b-black  ">
                  SOFTNER PLANT PUMP
                </div>
                {softner_plant_pump === 1 ? (
                  <>
                    <img
                      src="./empty1.gif"
                      alt="tank"
                      className="object-contain w-72 h-52 mb-2"
                    />
                  </>
                ) : (
                  <img
                    src="./fill.gif"
                    alt="tank"
                    className="object-contain w-72 h-52 mb-2"
                  />
                )}
                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2">
                    PUMP STATUS:
                    <span
                      className={`${
                        softner_plant_pump === 1 ? "bg-green-400" : "bg-red-400"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {softner_plant_pump === 1 ? "ON" : "OFF"}
                    </span>
                  </p>
                </div>

                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2">
                    <span
                      className={`${
                        softner_plant_tank === 0
                          ? "bg-blue-500"
                          : "bg-yellow-500"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {softner_plant_tank === 0 ? "TANK FULL" : "TANK EMPTY"}
                    </span>
                  </p>
                </div>
              </div>
              {/* tank 4 pump 4 */}
              <div className=" bg-white rounded-lg shadow-lg p-4 m-2 shadow-black">
                <div className="text-center text-sm font-bold border-b border-b-black  ">
                  DM PLANT PUMP
                </div>
                {dm_plant_pump === 1 ? (
                  <>
                    <img
                      src="./empty1.gif"
                      alt="tank"
                      className="object-contain w-72 h-52 mb-2"
                    />
                  </>
                ) : (
                  <img
                    src="./fill.gif"
                    alt="tank"
                    className="object-contain w-72 h-52 mb-2"
                  />
                )}
                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2">
                    PUMP STATUS:
                    <span
                      className={`${
                        dm_plant_pump === 1 ? "bg-green-400" : "bg-red-400"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {dm_plant_pump === 1 ? "ON" : "OFF"}
                    </span>
                  </p>
                </div>

                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2">
                    <span
                      className={`${
                        dm_plant_tank === 0 ? "bg-blue-500" : "bg-yellow-500"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {dm_plant_tank === 0 ? "TANK FULL" : "TANK EMPTY"}
                    </span>
                  </p>
                </div>
              </div>

              <div className=" bg-white rounded-lg shadow-lg p-4 m-2 flex flex-col justify-center items-center align-middle shadow-black">
                <div className="text-center text-sm font-bold border-b border-b-black  ">
                  JOCKEY PUMP
                </div>
                <div>
                  {jockey_pump === 1 ? (
                    <>
                      <img
                        src="./pump-animation.gif"
                        alt="tank"
                        className="object-contain w-72 h-64"
                      />
                    </>
                  ) : (
                    <img
                      src="./pump-static.png"
                      alt="tank"
                      className="object-contain w-72 h-64"
                    />
                  )}
                  <div className="flex items-center justify-center align-middle m-1">
                    <p className="text-sm font-bold mb-2 ">
                      PUMP STATUS:
                      <span
                        className={`${
                          jockey_pump === 1 ? "bg-green-400" : "bg-red-400"
                        } inline-block px-4 py-2 rounded-lg text-white`}
                      >
                        {jockey_pump === 1 ? "ON" : "OFF"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className=" bg-white rounded-lg shadow-lg p-4 m-2 shadow-black">
                <div className="text-center text-sm font-bold border-b border-b-black ">
                  MAIN PUMP
                </div>

                {main_pump === 1 ? (
                  <>
                    <img
                      src="./pump-animation.gif"
                      alt="tank"
                      className="object-contain w-72 h-64"
                    />
                  </>
                ) : (
                  <img
                    src="./pump-static.png"
                    alt="tank"
                    className="object-contain w-72 h-64"
                  />
                )}
                <div className="flex items-center justify-center align-middle m-1">
                  <p className="text-sm font-bold mb-2 ">
                    PUMP STATUS:
                    <span
                      className={`${
                        main_pump === 1 ? "bg-green-400" : "bg-red-400"
                      } inline-block px-4 py-2 rounded-lg text-white`}
                    >
                      {main_pump === 1 ? "ON" : "OFF"}
                    </span>
                  </p>
                </div>
              </div>
              {/* tank 5 pump 5 */}
            </div>
          </div>

          {/*2 extra pumps*/}

          <div className="flex justify-center items-center align-middle">
            <div className="bg-white rounded-lg shadow-lg p-4 m-2 w-72">
              <div className="text-center text-sm font-bold ">
                RUNNING MINUTES
              </div>
              <div className="text-center text-lg font-bold">{pump1runn}</div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-4 m-2 w-72">
              <div className="text-center text-sm font-bold ">
                RUNNING MINUTES
              </div>
              <div className="text-center text-lg font-bold">{pump2runn}</div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-4 m-2 w-72">
              <div className="text-center text-sm font-bold ">
                RUNNING MINUTES
              </div>
              <div className="text-center text-lg font-bold">{pump3runn}</div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-4 m-2 w-72">
              <div className="text-center text-sm font-bold ">
                RUNNING MINUTES
              </div>
              <div className="text-center text-lg font-bold">{pump5runn}</div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-4 m-2 w-72">
              <div className="text-center text-sm font-bold ">
                RUNNING MINUTES
              </div>
              <div className="text-center text-lg font-bold">{pump5runn}</div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-4 m-2 w-72">
              <div className="text-center text-sm font-bold ">
                RUNNING MINUTES
              </div>
              <div className="text-center text-lg font-bold">{pump6runn}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;

// {/* <BrowserRouter basename={process.env.PUBLIC_URL}>
// <Routes>
//   {/* <Route exact path="/" element={<Barchart />} /> */}
//   <Route exact path="/" element={<Login />} />
//   <Route exact path="/Home" element={<Home />} />
//   <Route exact path="/Dashboard" element={<Dashboard />} />
//   <Route exact path="/Table" element={<Table />} />
//   <Route exact path="/AlertTable" element={<AlertTable />} />
// </Routes>
// </BrowserRouter> */}
