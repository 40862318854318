import React from "react";
import Navbar from "./Navbar";
import Tank from "./Tank";
import Pump from "./Pump"
import Tag1 from "./Tag1";
import Login from "./Login";
import Home from "./Home";
import Main from "./Main";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

const App = () => {
 

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        {/* <Route exact path="/" element={<Barchart />} /> */}
        <Route exact path="/" element={<Login />} />
        <Route exact path="/Main" element={<Main />} />
        {/* <Route exact path="/Dashboard" element={<Dashboard />} />
        <Route exact path="/Table" element={<Table />} />
        <Route exact path="/AlertTable" element={<AlertTable />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;



// {/* <BrowserRouter basename={process.env.PUBLIC_URL}>
// <Routes>
//   {/* <Route exact path="/" element={<Barchart />} /> */}
//   <Route exact path="/" element={<Login />} />
//   <Route exact path="/Home" element={<Home />} />
//   <Route exact path="/Dashboard" element={<Dashboard />} />
//   <Route exact path="/Table" element={<Table />} />
//   <Route exact path="/AlertTable" element={<AlertTable />} />
// </Routes>
// </BrowserRouter> */}